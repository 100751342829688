import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

export default function Team() {
    const people = [
        {
            name: 'Abdur Rahim Chowdhury',
            role: 'Senior Merchandiser',
            imageSrc: 'abdur-rahim.jpg',
        },
        {
            name: 'Shafin Ahmed',
            role: 'Senior Merchandiser',
            imageSrc: 'shafin-ahmed.jpg',
        },
        {
            name: 'Abu Bakkar Siddique',
            role: 'Senior Merchandiser',
            imageSrc: 'abu-bakkar.jpg',
        },
        {
            name: 'Ariful Islam',
            role: 'Senior Merchandiser',
            imageSrc: 'ariful-islam.jpg',
        },
        {
            name: 'Smriti Sarakar',
            role: 'Merchandiser',
            imageSrc: 'smriti-sarakar.jpg',
        },
        {
            name: 'Rasel Perves',
            role: 'Merchandiser',
            imageSrc: 'rasel-perves.jpg',
        },
        {
            name: 'Mehedin Hasan',
            role: 'Merchandiser',
            imageSrc: 'mehedi-hasan.jpg',
        },
        {
            name: 'Rayhan Islam',
            role: 'Merchandiser',
            imageSrc: 'rayhan-islam.jpg',
        },
        {
            name: 'Mizanur Rahman',
            role: 'Junior Merchandiser',
            imageSrc: 'mizanur-rahman.jpg',
        },
        {
            name: 'Aminul Islam',
            role: 'Junior Merchandiser',
            imageSrc: 'aminul-islam.jpg',
          },
        {
          name: 'Abu Rayhan',
          role: 'Junior Merchandiser',
          imageSrc: 'abu-rayhan.jpg',
        },
        {
          name: 'Shamim Reza',
          role: 'Assistant Merchandiser',
          imageSrc: 'shamim-reza.jpg',
        },
        {
            name: 'Habibur Rahman',
            role: 'General Administrator',
            imageSrc: 'habibur-rahman.jpg',
        },
        {
          name: 'Kuddus Ali',
          role: 'Head of Thread & Fabric Supply',
          imageSrc: 'kuddus-ali.jpg',
        },
        {
          name: 'Golam Jelani Pintu',
          role: 'Head of Purchasing',
          imageSrc: 'golam-jelani-pintu.jpg',
        },
        {
            name: 'Faisal Robin',
            role: 'Quality Manager',
            imageSrc: 'faisal-robin.jpg',
        },
        {
            name: 'Md Faysal',
            role: 'Graphic Designer',
            imageSrc: 'md-faysal.jpg',
        },
        {
            name: 'Mijanur Rahman Khan',
            role: 'Financial Manager',
            imageSrc: 'mijanur-rahman.jpg',
        },
        {
            name: 'Masura Akter',
            role: 'Commercial Coordinator',
            imageSrc: 'masura-akter.jpg',
        },
        {
          name: 'Sohanur Reza',
          role: 'Accounts Officer',
          imageSrc: 'sohanur-reza.jpg',
        },
        {
          name: 'Arafat Rhomen',
          role: 'Quality Assurance Officer',
          imageSrc: 'arafat-rhomen.jpg',
        },
        {
          name: 'Khandakar MD. Jashim Uddin',
          role: 'Commercial Manager',
          imageSrc: 'khandakar.jpg',
        },
        {
          name: 'MD. Kamal Hossain Mazumder',
          role: 'Commercial Manager',
          imageSrc: 'kamal-hossain-mazumder.jpg',
        },
        {
          name: 'MD. Sohel',
          role: 'Junior Commercial Officer',
          imageSrc: 'sohel.jpg',
        },
    ]
      const data = useStaticQuery(
          graphql`
          query {
              allFile(filter: {relativeDirectory: {eq: "team"}}) {
                  edges {
                      node {
                          childImageSharp {
                              gatsbyImageData(placeholder: BLURRED)
                              fluid {
                                  originalName
                              }
                          }
                      }
                  }
              }
          }
          `
      )
      const imageData = data.allFile.edges
      
      people.forEach(el => {console.log(el); el.image = imageData[imageData.findIndex(i => i.node.childImageSharp.fluid.originalName === el.imageSrc)].node});

    return (
      <div >
        <div className="max-w-7xl mx-auto py-12 text-center lg:py-24">
          <div className="space-y-8 sm:space-y-12">
            <div className="text-left md:text-center space-y-5 sm:mx-auto sm:max-w-3xl sm:space-y-4 lg:max-w-5xl">
              <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">The Team</h2>
              <p className="text-md lg:text-lg text-gray-700">
              Keepers of our customer’s trust in our quality and time-commitments, each member of the team has been trained according to Sara Fashionwear standards from the beginning of their careers. The merchandising team, who sustain core operations for product development and production, have Textile Engineering degrees, supported by vast experience in the sector. 
              </p>
            </div>
            <ul
              role="list"
              className="mx-auto grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-4 md:gap-x-6 lg:max-w-5xl lg:gap-x-8 lg:gap-y-12 xl:grid-cols-4"
            >
              {people.map((person) => (
                <li key={person.name}>
                  <div className="space-y-4">
                    <GatsbyImage className="mx-auto h-28 w-28 rounded-full md:w-36 md:h-36 lg:w-44 lg:h-44" image={getImage(person.image)} alt={person.name} />
                    <div className="space-y-2">
                      <div className="text-xs font-medium lg:text-sm">
                        <h3>{person.name}</h3>
                        <p className="text-primary-900">{person.role}</p>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    )
  }