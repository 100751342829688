import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

export default function Management() {
    const people = [
        {
            name: 'Henry Abu',
            role: 'Chief Executive Officer',
            image: <StaticImage src='../images/team/henry-abu.jpg' className="object-cover rounded-sm" alt='Henry Abu' />,
            linkedinUrl: 'https://www.linkedin.com/in/henry-abu-4b67291ba',
            bio: 'Founder and leader of the company, Mr. Abu is involved in every company operation. In fact, over the years, he has acquired first-hand experience in every step of the clothing industry supply chain, collecting priceless expertise, and a well-rounded perspective of client needs. His ambitious personality is the fuel of our company spirit.',
        },
        {
            name: 'Sara Abu',
            role: 'Head of Business Development',
            image: <StaticImage src='../images/team/sara-abu.jpg' className="object-cover rounded-sm" alt='Sara Abu' />,
            linkedinUrl: 'https://www.linkedin.com/in/sara-abu',
            bio: 'Ms. Abu holds a BSc in Economics & Management from Universitá Commerciale L. Bocconi (Italy) and a MSc in Innovation and Entrepreneurship from ESADE Business School (Spain). She is leading business development projects in every continent, establishing new strong relationships with potential clients who share our values and dedication to premium quality.',
        },
        // {
        //     name: 'Rokhsana Akhter',
        //     role: 'Chairman',
        //     image: <StaticImage src='../images/team/rokhsana-akhter.jpg' className="object-cover rounded-sm" alt='Rokhsana Akhter' />,
        //     bio: 'Our Madam Chairman is an inactive but important part of the managing team, with solid knowledge of the fashion industry.  ',
        // },
    ]

    return (
      <div>
        <div className="mx-auto pt-4 pb-12 px-0 max-w-7xl lg:pb-20 ">
          <div className="space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
            <div className="space-y-5 sm:space-y-4">
              <h2 className="text-2xl font-extrabold tracking-tight sm:text-3xl -mb-6">Management</h2>
              {/* <p className="text-base text-gray-700">
              We at Sara Fashionwear are committed to providing full attention to client needs. Therefore, at least one member of our management team is present in Europe all year round, making in-person meetings possible whenever needed. Communication between ourselves and the client is of utmost importance throughout the manufacturing process and we speak your language.
              </p> */}
            </div>
            <div className="lg:col-span-2">
              <ul
                role="list"
                className="space-y-12 sm:divide-y sm:divide-gray-200 sm:space-y-0 sm:-mt-8 lg:gap-x-8 lg:space-y-0"
              >
                {people.map((person) => (
                  <li key={person.name} className="sm:py-8">
                    <div className="space-y-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0">
                      <div className="!aspect-w-3 !aspect-h-2 sm:!aspect-w-3 sm:!aspect-h-4">
                        {person.image}
                      </div>
                      <div className="sm:col-span-2">
                        <div className="space-y-4">
                          <div className="text-lg leading-6 font-medium space-y-1">
                            <h3>{person.name}</h3>
                            <p className="text-primary-900">{person.role}</p>
                          </div>
                          <div className="text-base">
                            <p className="text-gray-700">{person.bio}</p>
                          </div>
                          <ul role="list" className="flex space-x-5">
                            {person.linkedinUrl? <li>
                              <a href={person.linkedinUrl} target='_blank' rel="noreferrer" className="text-gray-700 hover:text-gray-900">
                                <span className="sr-only">LinkedIn</span>
                                <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                                  <path
                                    fillRule="evenodd"
                                    d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </a>
                            </li> : null}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }