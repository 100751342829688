import * as React from "react"
import { Fragment } from 'react'
import { Tab } from '@headlessui/react'

import Layout from "../components/layout"
import Management from "../components/management"
import Seo from "../components/seo"
import Team from '../components/team'
import TeamIntro from "../components/teamIntro"
import Testimonial from '../components/testimonial'
import CompanyHistory from '../components/companyHistory'
import Vision from "../components/vision"

const AboutPage = () => {
  const tabs = [
    {
      name: 'Team',
      component: <div><Management/><Testimonial/><Team /></div>
    },
    {
      name: 'History',
      component: <CompanyHistory />
    },
    {
      name: 'Vision',
      component: <Vision />
    },
  ]

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <Layout>
      <Seo title='Sara Fashionwear'/>
      <TeamIntro />
      <section aria-labelledby="features-heading" className="max-w-7xl mx-auto py-12 sm:px-2 lg:px-8">
        <div className="max-w-2xl mx-auto px-4 lg:px-0 lg:max-w-none">
          <div className="max-w-5xl">
            <h2 id="features-heading" className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              We grow together
            </h2>
            <p className="mt-4 text-gray-700">
            We at Sara Fashionwear are committed to providing full attention to client needs. Therefore, at least one member of our management team is present in Europe all year round, making in-person meetings possible whenever needed. Communication between ourselves and the client is of utmost importance throughout the manufacturing process and we speak your language.
            </p>
          </div>
          <Tab.Group as="div" className="mt-4">
            <div className="-mx-4 flex overflow-x-auto sm:mx-0">
              <div className="flex-auto px-4 border-b border-gray-200 sm:px-0">
                <Tab.List className="-mb-px flex space-x-10">
                  {tabs.map((tab) => (
                    <Tab
                      key={tab.name}
                      className={({ selected }) =>
                        classNames(
                          selected
                            ? 'border-gray-900 text-gray-900'
                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                          'whitespace-nowrap py-6 border-b-2 font-medium text-lg'
                        )
                      }
                    >
                      {tab.name}
                    </Tab>
                  ))}
                </Tab.List>
              </div>
            </div>
            <Tab.Panels as={Fragment}>
              {tabs.map((tab) => (
                <Tab.Panel key={tab.name} className="space-y-16 pt-10 lg:pt-16">
                  {tab.component}
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>
        </div>
      </section>
    </Layout>
  )
}

export default AboutPage