import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

export default function Vision() {
    return (
      <div className="relative ">
        <div className="lg:absolute lg:inset-0">
          <div className="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
            <StaticImage
              className="h-64 w-full rounded-sm object-cover lg:absolute lg:h-full"
              src="../images/chairman.jpg"
              alt=""
            />
          </div>
        </div>
        <div className="relative pt-12 pb-16 sm:pt-16 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
          <div className="lg:col-start-2 lg:pl-8">
            <div className="text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0">
              <h2 className="leading-6 text-primary-900 font-semibold tracking-wide uppercase">Our future</h2>
              <h3 className="mt-2 text-2xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-3xl">
                A word from our Chairman
              </h3>
              <div className="mt-5 prose prose-indigo text-gray-700">
                <p className='pb-2'>
                For years now Bangladesh has held a leading position in the clothing manufacturing industry, quickly becoming the first choice for big and small fashion brands all over the world.   
                </p>
                <p className='pb-2'>
                Standards of quality and compliance have been consistently improved in the global community and Sara Fashionwear has never failed to keep up, striving to innovate and adapt in this ever-changing industry. 
                </p>
                <p className='pb-2'>
                We enjoy the challenge of putting our minds together to deliver quality goods that adhere to the highest expectations. Our vision is to be a driving force of change in the country, guaranteeing not only the best products for our clients, but catalyzing the process of social development that this industry needs. 
                </p>
                <p className='pb-2'>
                In our effort to transform into a triple bottom line business entity, growing our success in tandem with the society around us, we see ourselves on a path towards a more fair, equal, and prosperous clothing manufacturing sector. We have vision and openness to change for the better. From now, it is one initiative at a time towards that shared goal.  
                </p>
              <p className="mt-8 text-gray-700">
                Rokhsana Akhter
              </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }