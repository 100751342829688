import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'


export default function companyHistory() {
  const sections = [
      {
        name: 'Passion for excellence',
        description:
        'Sara Fashionwear is a family owned business, serving sophisticated fashion brands since 2014. We have learned to deal with any challenge and we have learnt to do it with passion for excellence, setting up a high benchmark for the clothing manufacturing industry in Bangladesh. The management has resided in Italy since 1984, and is therefore committed to a European mindset that has largely shaped our corporate vision and values.',
        image: <StaticImage src='../images/history-1.jpg' alt='Our team at work' className="object-center object-cover" />
      },
      {
        name: 'Culture of meritocracy',
        description:
        'We have created a workplace and work ethics that stand out in this country based on a culture of meritocracy, where each individual is able to pursue long-term personal growth, knowing that their effort is recognized as an asset. We began with strong values and handpicked our team as the company grew in size, to uphold those values as their own. This is largely reflected in the way we approach client relationships, always addressing them as business partners, as we know that in their success lies our own.',
        image: <StaticImage src='../images/history-2.jpg' alt='Our team at work' className="object-center object-cover" />
      },
    ]
  
  function classNames(...classes) {
      return classes.filter(Boolean).join(' ')
    }

    return (
      <div >
        <div className="max-w-2xl mx-auto py-4 lg:max-w-7xl ">
          <div className="mx-auto text-left">
            <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">Our Story</h2>
            <p className="mt-4 text-gray-700">
              Sara Fashionwear is not just run by a family, but runs on family values. Once you enter business with us, we will always meet you halfway. 
            </p>
          </div>
  
          <div className="mt-16 space-y-16">
            {sections.map((section, featureIdx) => (
              <div
                key={section.name}
                className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:gap-x-8 lg:items-center"
              >
                <div
                  className={classNames(
                    featureIdx % 2 === 0 ? 'lg:col-start-1' : 'lg:col-start-8 xl:col-start-9',
                    'mt-6 lg:mt-0 lg:row-start-1 lg:col-span-5 xl:col-span-4'
                  )}
                >
                  <h3 className="text-lg font-medium text-gray-900">{section.name}</h3>
                  <p className="mt-2 text-base text-gray-700">{section.description}</p>
                </div>
                <div
                  className={classNames(
                    featureIdx % 2 === 0 ? 'lg:col-start-6 xl:col-start-5' : 'lg:col-start-1',
                    'flex-auto lg:row-start-1 lg:col-span-7 xl:col-span-8'
                  )}
                >
                  <div className="!aspect-w-4 !aspect-h-2 rounded-sm bg-gray-100 overflow-hidden ">
                    {section.image}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }