import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

export default function TeamIntro() {
    return (
      <div className="hidden md:block relative pt-16 overflow-hidden sm:pt-24 lg:pt-32">
        <div className="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
          {/* <div>

            <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
              We grow together
            </p>
            <p className="mt-5 hidden md:block max-w-prose mx-auto text-xl text-gray-700">
              Phasellus lorem quam molestie id quisque diam aenean nulla in. Accumsan in quis quis nunc, ullamcorper
              malesuada. Eleifend condimentum id viverra nulla.
            </p>
          </div> */}
          <div className="">
            <StaticImage
              className="rounded-sm "
              src="../images/team.jpg"
              alt="Team"
            />
          </div>
        </div>
      </div>
    )
  }